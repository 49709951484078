import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import ProfileUpdateForm from '@/components/ProfileUpdateForm'
import PasswordUpdateForm from '@/components/PasswordUpdateForm'
import Tiles from '@/components/Tiles'
import UserAvatar from '@/components/UserAvatar'
import { Vue, Component } from 'vue-property-decorator'
import { userStore } from '@/store';

@Component({
  components: {
    UserAvatar, Tiles, PasswordUpdateForm, ProfileUpdateForm, HeroBar, TitleBar, CardComponent
  }
})
export default class Login extends Vue {
  userStore = userStore;

  get titleStack() {
    return [
      'Админ',
      'Профил'
    ]
  }

  render() {
    return <div>
      <titleBar titleStack={this.titleStack} />
      <heroBar>
        Профил
        <routerLink slot="right" to="/" class="button">
          Почетна
        </routerLink>
      </heroBar>
      <section class="section is-main-section">
        <tiles>
          <profileUpdateForm class="tile is-child" />
          <cardComponent title="Profile" icon="account" class="tile is-child">
            <userAvatar class="image has-max-width is-aligned-center" />
            <hr />
            <bField label="Name">
              <bInput value={userStore.userName} customClass="is-static" readonly />
            </bField>
            <hr />
            <bField label="E-mail">
              <bInput value={userStore.userEmail} customClass="is-static" readonly />
            </bField>
          </cardComponent>
        </tiles>
        <password-update-form />
      </section>
    </div>
  }
}

import CardComponent from '@/components/CardComponent'
import FilePicker from '@/components/FilePicker'
import { Vue, Component } from 'vue-property-decorator'
import { modifiers as m } from "vue-tsx-support";

@Component({
  components: {
    CardComponent,
    FilePicker
  }
})
export default class PasswordUpdateForm extends Vue {
  isLoading = false;
  form = {
    password_current: '',
    password: '',
    password_confirmation: ''
  }

  submit() {
    this.isLoading = true
    setTimeout(() => {
      this.isLoading = false
      this.$buefy.snackbar.open({
        message: 'Updated',
        queue: false
      });
    }, 500)
  }

  render() {
    return <cardComponent title="Change Password" icon="lock">
      <form onSubmit={m.prevent(this.submit)}>
        <bField horizontal label="Current password" message="Required. Your current password">
          <bInput name="password_current" type="password" vModel={this.form.password_current} required
            autcomplete="current-password" />
        </bField>
        <hr />
        <bField horizontal label="New password" message="Required. New password">
          <bInput name="password" type="password" vModel={this.form.password} required autocomplete="new-password" />
        </bField>
        <bField horizontal label="Confirm password" message="Required. New password one more time">
          <bInput name="password_confirmation" type="password" vModel={this.form.password_confirmation} required
            autocomplete="new-password" />
        </bField>
        <hr />
        <bField horizontal>
          <div class="control">
            <button type="submit" class={{ 'button is-primary': true, 'is-loading': this.isLoading }}>
              Submit
            </button>
          </div>
        </bField>
      </form>
    </cardComponent>;
  }
}

import FilePicker from '@/components/FilePicker'
import CardComponent from '@/components/CardComponent'
import { userStore } from '@/store';
import { Vue, Component } from 'vue-property-decorator'
import { modifiers as m } from "vue-tsx-support";

@Component({
  components: {
    CardComponent,
    FilePicker
  }
})
export default class ProfileUpdateForm extends Vue {
  isFileUploaded: boolean = false;
  isLoading: boolean = false;
  form = {
    name: '',
    email: ''
  }
  mounted() {
    this.form.name = userStore.userName
    this.form.email = userStore.userEmail
  }
  submit() {
    this.isLoading = true
    setTimeout(() => {
      this.isLoading = false
      // userStore.user(this.form)
      this.$buefy.snackbar.open({
        message: 'Updated',
        queue: false
      })
    }, 500)
  }

  render() {
    return <cardComponent title="Edit Profile" icon="account-circle">
      <form onSubmit={m.prevent(this.submit)}>
        <bField horizontal label="Avatar">
          <file-picker />
        </bField>
        <hr />
        <bField horizontal label="Name" message="Required. Your name">
          <bInput vModel={this.form.name} name="name" required />
        </bField>
        <bField horizontal label="E-mail" message="Required. Your e-mail">
          <bInput vModel={this.form.email} name="email" type="email" required />
        </bField>
        <hr />
        <bField horizontal>
          <div class="control">
            <button type="submit" class={{ 'button is-primary': true, 'is-loading': this.isLoading }}>
              Submit
            </button>
          </div>
        </bField>
      </form>
    </cardComponent>;
  }
}
